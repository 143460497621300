<template>
    <div id="paper" class="paper">
        <Header />
        <div class="container content">
            <div class="paper-content">
                <div class="query-container">
                    <div class="query-item">
                        <span class="label">分类:</span>
                        <span class="condition">
                            <span
                                v-for="item in subjectTags"
                                :key="item.id"
                                :class="item.id === activeSubjectTag ? 'active' : ''"
                                @click="onChangeSubjectTag(item)"
                            >
                                {{ item.name }}
                            </span>
                        </span>
                    </div>
                    <template v-for="item in tagList">
                        <div v-if="item.childTags.length < 30" :key="item.id" class="query-item">
                            <span class="label">{{ item.name }}:</span>
                            <span class="condition">
                                <span
                                    :class="activeTags[`item${item.id}`] === tag.id ? 'active' : ''"
                                    v-for="tag in item.childTags"
                                    :key="tag.id"
                                    @click="onChangeQueryTag(item, tag)"
                                >
                                    {{ tag.name }}
                                </span>
                            </span>
                        </div>
                        <div v-if="item.childTags.length >= 30" :key="item.id" class="query-item">
                            <span class="label">{{ item.name }}:</span>
                            <span class="condition">
                                <el-select
                                    filterable
                                    clearable
                                    v-model="activeTags[`item${item.id}`]"
                                    :placeholder="`请选择${item.name}`"
                                    @change="onSelectChange"
                                >
                                    <el-option
                                        v-for="tag in item.childTags"
                                        :key="tag.id"
                                        :label="tag.name"
                                        :value="tag.id"
                                    />
                                </el-select>
                            </span>
                        </div>
                    </template>
                </div>

                <div class="paper-list">
                    <div class="paper-item" v-for="paper in paperList" :key="paper.id">
                        <div>
                            <div class="paper-title">{{ paper.title }}</div>
                            <div class="paper-other">
                                <span>总分: {{ paper.score }}分</span>
                                <span>题目: {{ paper.questionCnt }}题</span>
                            </div>
                        </div>
                        <div class="btns">
                            <van-button @click="toPaperResultPage(paper)" class="last-btn" v-if="paper.hasLastCommit">
                                上次作答结果
                            </van-button>
                            <van-button v-if="paper.unlockedFlag" @click="toExamDetailsPage(paper)" class="study-btn">
                                开始答题
                            </van-button>
                            <van-button v-else disabled>开始答题</van-button>
                        </div>
                    </div>
                </div>
                <Pagination @current-change="getPaperListApi" :page.sync="page" :size="10" :total="total" />
            </div>
        </div>
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import Pagination from '@/components/global/Pagination'
import { getPaperList, getLastCommitSummary, getSubjectTags, getAllTags } from '@/api/paperApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'paper',
    components: { Header, Fotter, Pagination },
    data() {
        return {
            activeSubjectTag: '',
            subjectTags: [],
            tagList: [],
            activeTags: {},

            paperList: [],
            page: 1,
            total: 0,
        }
    },
    watch: {
        userInfo: function () {
            this.getPaperListApi()
        },
    },
    computed: { ...mapGetters(['userInfo']) },
    created() {
        const { id } = this.$route.query
        if (id) {
            this.activeSubjectTag = parseInt(id)
        } else {
            this.activeSubjectTag = this.$store.state.paper.activeSubjectTag
            this.activeTags = this.$store.state.paper.activeTags
            this.page = this.$store.state.paper.currentPage
        }
        this.getSubjectTagsApi()
    },
    methods: {
        toExamDetailsPage(item) {
            if (this.userInfo) {
                this.$router.push(`/paperDetails/${item.paperNo}`)
            } else {
                this.$toast('请先登录')
                this.$store.dispatch('user/showLoginDialog')
            }
        },
        toPaperResultPage(item) {
            this.$router.push(`/paperResult/${item.paperNo}`)
        },

        onChangeSubjectTag(item) {
            if (this.activeSubjectTag !== item.id) {
                this.activeSubjectTag = item.id
                this.page = 1
                this.activeTags = {}
                this.$store.dispatch('paper/setActiveSubjectTag', item.id)
                this.getAllTagsApi()
                this.getPaperListApi()
            }
        },
        onSelectChange() {
            this.$store.dispatch('paper/setActiveTags', this.activeTags)
            this.page = 1
            this.getPaperListApi()
        },
        onChangeQueryTag(item, tag) {
            if (!this.activeTags[`item${item.id}`] || this.activeTags[`item${item.id}`] !== tag.id) {
                this.activeTags[`item${item.id}`] = tag.id
            } else {
                this.activeTags[`item${item.id}`] = ''
            }
            this.$store.dispatch('paper/setActiveTags', this.activeTags)
            this.page = 1
            this.getPaperListApi()
        },
        getSubjectTagsApi() {
            getSubjectTags().then((res) => {
                this.subjectTags = res.data
                if (!this.activeSubjectTag) {
                    this.activeSubjectTag = res.data[0]['id']
                }
                this.getAllTagsApi()
                this.getPaperListApi()
            })
        },
        getAllTagsApi() {
            getAllTags({ tagIds: [this.activeSubjectTag] }).then((res) => {
                res.data.shift()
                this.tagList = res.data
                res.data.forEach((item) => {
                    if (!this.activeTags[`item${item.id}`]) {
                        this.activeTags[`item${item.id}`] = ''
                    }
                })
            })
        },
        getPaperListApi() {
            const params = { page: this.page, showPage: true, size: 10 }
            let tags = Object.values(this.activeTags)
            tags.push(this.activeSubjectTag)
            tags = tags.filter((tagId) => !!tagId)
            params.andTags = tags
            this.paperList = []
            getPaperList(params).then((res) => {
                if (res.data.list.length !== 0) {
                    const ids = []
                    res.data.list.forEach((item) => {
                        ids.push(item.id)
                    })
                    if (this.userInfo) {
                        getLastCommitSummary(ids).then((commit) => {
                            const commitMap = {}
                            commit.data.forEach((item) => {
                                commitMap[item.paperId] = item
                            })
                            res.data.list.forEach((item) => {
                                item.hasLastCommit = !!commitMap[item.id]
                            })
                            this.paperList = res.data.list
                        })
                    } else {
                        this.paperList = res.data.list
                    }
                } else {
                    this.paperList = []
                }
                this.total = res.data.total
                this.page = res.data.pageNum
                this.$store.dispatch('paper/setCurrentPage', this.page)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#paper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.paper {
    width: 100%;
    background: $theme-bg-color;
    .container {
        margin: 0 auto;
        padding: 24px;
        width: $center-width;
    }
    .paper-content {
        border-radius: 8px;
        min-height: 400px;
        background: #ffffff;
    }

    .query-container {
        padding: 24px 24px 8px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 4px;
        min-height: 117px;
        box-sizing: border-box;
        .query-item {
            margin-bottom: 6px;
            display: flex;
            .label {
                line-height: 36px;
                font-size: 14px;
                color: #666666;
                flex-shrink: 0;
                margin-right: 12px;
            }
            .condition {
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                font-size: 14px;
                > span {
                    padding: 6px 10px;
                    height: 26px;
                    margin: 5px 8px 5px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    border-radius: 2px;
                    color: #33312f;
                    cursor: pointer;
                    &.active {
                        border-radius: 2px;
                        background: rgba(238, 195, 88, 0.08);
                        color: $theme-color;
                    }
                }
            }
        }
    }

    .paper-list {
        padding: 0 36px;
        min-height: 320px;
        .paper-item {
            padding: 16px 0;
            border-bottom: 1px solid #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:last-child {
                border: none;
            }
        }
        .paper-title {
            display: block;
            font-weight: 700;
            font-size: 16px;
            color: #33312f;
            line-height: 22px;
            margin-bottom: 20px;
        }
        .paper-other {
            font-size: 14px;
            color: #99948d;
            line-height: 14px;
            span {
                margin-right: 16px;
            }
        }
        .btns {
            display: flex;
            align-items: center;
            .last-btn {
                border: 1px solid $theme-color;
                color: $theme-color;
                font-size: 13px;
                box-sizing: border-box;
            }
            .study-btn {
                flex-shrink: 0;
                background: $theme-color;
                border: none;
                width: 96px;
                height: 42px;
                border-radius: 4px;
                color: #ffffff;
                margin: 0 20px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 980px) and (min-width: 720px) {
    .paper {
        .container {
            padding: 16px;
            width: 100%;
            box-sizing: border-box;
        }
        .query-container {
            padding: 16px 16px 4px;
        }
    }
}

@media screen and (max-width: 720px) {
    .paper {
        .container {
            padding: 0;
            width: 100%;
        }
        .paper-content {
            border-radius: 0;
            min-height: 400px;
            background: #ffffff;
        }

        .query-container {
            padding: 12px 12px 4px;
        }

        .paper-list {
            padding: 0 16px;
            .paper-item {
                padding: 12px 0;
            }
            .paper-title {
                font-size: 14px;
                margin-bottom: 12px;
            }
            .paper-other {
                font-size: 12px;
            }
            .btns {
                display: flex;
                align-items: center;
                .last-btn {
                    width: 72px;
                    height: 40px;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 400;
                    letter-spacing: 1px;
                    box-sizing: border-box;
                    padding: 0 6px;
                    line-height: 1.2em;
                }
                .study-btn {
                    width: 40px;
                    height: 40px;
                    font-size: 12px;
                    line-height: 1.4em;
                    font-weight: 400;
                    box-sizing: border-box;
                    letter-spacing: 2px;
                    padding: 0 6px;
                }
            }
        }
    }
}
</style>
