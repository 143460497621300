<template>
    <div class="pagination">
        <van-icon @click="onPageChange(page - 1)" v-if="page !== 1 && total !== 0" class="icon" name="arrow-left" />
        <van-icon v-else class="disabled" name="arrow-left" />
        <div class="pager">
            <template v-for="i in pageList">
                <div
                    @click="onPageChange(i)"
                    :class="`number ${page === i ? 'active' : ''}`"
                    :key="i"
                    v-if="showPage.includes(i)"
                >
                    {{ i }}
                </div>
            </template>
        </div>
        <van-icon
            @click="onPageChange(page + 1)"
            v-if="page !== pageList[pageList.length - 1] && total !== 0"
            class="icon"
            name="arrow"
        />
        <van-icon v-else class="disabled" name="arrow" />
    </div>
</template>
<script>
export default {
    props: ['page', 'size', 'total'],
    data() {
        return {
            pageList: [],
        }
    },
    watch: {
        total: function () {
            const _n = this.total % this.size === 0 ? this.total / this.size : parseInt(this.total / this.size) + 1
            const _list = []
            for (let index = 1; index <= _n; index++) {
                _list.push(index)
            }
            this.pageList = _list
        },
    },
    computed: {
        showPage: function () {
            const pagerCount = 4
            let _num = 1
            if ([1, 2].includes(this.page)) {
                _num = 1
            } else if (this.page >= this.pageList.length - (pagerCount - 2)) {
                _num = this.pageList.length - pagerCount + 1
            } else {
                _num = this.page - 1
            }
            const _list = []
            for (let index = 0; index < pagerCount; index++) {
                _list.push(index + _num)
            }
            return _list
        },
    },
    methods: {
        onPageChange(num) {
            this.$emit('update:page', num)
            this.$emit('current-change')
        },
    },
}
</script>

<style lang="scss" scoped>
.pagination {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .pager {
        display: flex;
        align-items: center;
        justify-content: center;
        .number {
            font-size: 13px;
            min-width: 35.5px;
            height: 28px;
            line-height: 28px;
            padding: 0 4px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
            font-weight: 600;
            user-select: none;
        }
        .active {
            color: $theme-color;
        }
    }
}
.van-icon {
    padding: 0 12px;
}
.icon:hover {
    color: $theme-color;
    cursor: pointer;
}
.disabled {
    color: #aaaaaa;
    cursor: no-drop;
}
</style>
