import { nuwa_post_form, nuwa_post } from './http'
import store from '@/store/index'

export const getSubjectTags = (data) => {
    return nuwa_post({
        path: '/questionPaper/subjectTags',
        data,
    })
}
export const getAllTags = (data) => {
    return nuwa_post({
        path: '/questionPaper/tags',
        data,
    })
}

export const queryTags = (data) => {
    return nuwa_post_form({
        path: '/questionPaper/queryTags',
        data,
    })
}

export const getPaperList = (data) => {
    return nuwa_post({
        path: '/questionPaper/list',
        data,
    })
}

export const getPaperInfo = (data) => {
    return nuwa_post_form({
        path: '/questionPaper/get',
        data,
    })
}

export const getPaperBlockQuestions = (data) => {
    return nuwa_post_form({
        path: '/questionPaper/getPaperBlockQuestions',
        data,
    })
}

export const commitAnswer = (paperNo, data, mockFlag) => {
    const { userInfo } = store.state.user
    let url = `/questionPaper/commitAnswer?userId=${userInfo.id}&paperNo=${paperNo}`
    if (mockFlag) {
        url += `&mockFlag=true`
    }
    return nuwa_post({ path: url, data })
}

export const getLastCommitAnswer = (paperNo) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/questionPaper/getLastCommitAnswer',
        data: {
            paperNo: paperNo,
            userId: userInfo.id,
        },
    })
}

export const getLastCommitSummary = (paperIds) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/questionPaper/lastCommitSummary',
        data: {
            paperIds: paperIds.join(','),
            userId: userInfo.id,
        },
    })
}

export const getAnalysis = (paperNo) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/questionPaper/getAnalysis',
        data: {
            paperNo: paperNo,
            userId: userInfo.id,
        },
    })
}
